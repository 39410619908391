import './customerSource.scss';

/* eslint-disable no-console */
import React, {Component, Fragment,} from 'react';

import {
    ALERT_MESSAGES,
    API_SOURCE,
    APP_BOOLEANS,
    APPLICATION_MODES,
    FORM_OPERATIONS,
    SWITCH_TEXT,
    VALIDATION_MESSAGES,
} from 'appConstants';
import {customAlphabet} from 'nanoid';
import Loader from 'react-loaders';
import {Row, Switch,} from 'react-materialize';

import {dynamicSort} from '../../../utils/objectUtil';
import {trimCapitalize} from '../../../utils/stringUtil';
import {TatvamButton, TatvamCol, TatvamDatePicker, TatvamMultiSelect,} from '../../functionalComponents';
import AlertBox from '../alertBox';
import ApiDetails from './apiDetails';

class ApiSourceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            preLoader: false,
            formSelection: true,
            categoryList: [],
            apiSource: '',
            isAnyFormChanged: false,
            apiSourceList: [],
            selectedApiSource: "",
            commentTypeList: [],
            apiDetails: {},
            commentType: "",
            isValid: true,
            dateValid: true,
            validLicenseDate: true,
            details: {},
            isPublic: false,
            isTrackable: false,
            isSingleReviewRefresh: false,
            cutomerResponseList: [],
            customerResponse: "",
            isAccuracy: false,
            // ratingTypeListOption:[
            //     {label:"AI Rating",value:"AI Rating"},
            //     {label:"Google Rating",value:"Google Rating"},
            //     {label:"Tatvam Rating",value:"Tatvam Rating"},
            // ],
            // selectedRatingType:""
        };
    }

    ifAnyFieldChanged = (cb) => {
        this.setState({isAnyFormChanged: cb});
    };

    _handleSourceSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                apiSource: true
            },
            selectedApiSource: selectedOption
        });

        if (selectedOption.label.toLowerCase() === 'facebook' || selectedOption.label.toLowerCase().includes("fb")) {
            this.setState({
                facebook: true,
                google: false,
                instagram: false,
                twitter: false
            });
        } else if (selectedOption.label.toLowerCase() === 'instagram') {
            this.setState({
                instagram: true,
                google: false,
                facebook: false,
                twitter: false
            });
        } else if (selectedOption.label.toLowerCase() === 'google reviews' || selectedOption.label.toLowerCase() === 'google') {
            this.setState({
                google: true,
                instagram: false,
                facebook: false,
                twitter: false
            });
        } else {
            this.setState({
                twitter: true,
                instagram: false,
                facebook: false,
                google: false
            });
        }

        if (this.props.sourceList) {
            this.setState({details: this.props.sourceList[selectedOption.value] ? this.props.sourceList[selectedOption.value].details : {}});
        }
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({});
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _validations = () => {
        let isValid, dateValid, validLicenseDate;
        const commentType = this.state.commentType.label;
        // const selectedRatingType = this.state.selectedRatingType.label;

        if (this.state.valid_from !== "" || this.state.valid_upto !== "") {
            if (new Date(this.state.valid_from) <= new Date(this.state.valid_upto)) {
                if ((new Date(this.state.valid_from) < new Date(this.state.license_from)) || (new Date(this.state.valid_upto) > new Date(this.state.license_upto))) {
                    validLicenseDate = false;
                } else {
                    validLicenseDate = true
                }
                dateValid = true;
                this.setState({
                    dateValid,
                    validLicenseDate
                });
            } else {
                dateValid = false;
                validLicenseDate = true;
                this.setState({
                    dateValid,
                    validLicenseDate
                });
            }
        } else {
            dateValid = true;
            validLicenseDate = true;
            this.setState({
                dateValid,
                validLicenseDate
            });
        }

        const details = Object.keys(this.state.details).includes("");

        if (this.state.details && !details && commentType && this.state.valid_from && this.state.valid_upto) {
            isValid = true;
            this.setState({isValid,});
        } else {
            isValid = false;
            this.setState({isValid});
        }

        return Promise.resolve(true);
    }

    _handleSave = () => {
        this._validations().then((response) => {
            let {google, facebook, instagram, twitter} = this.state;
            let {data} = "";
            let selectedFormValid = [];
            let sourceData = [];
            let actualSourceName = "";
            let is_live_feed, is_respondable, is_public, is_action_trackable, is_single_review_refresh,is_accuracy;
            const {status, isLiveFeed, isRespondable, isPublic, isTrackable, isSingleReviewRefresh,isAccuracy} = this.state;
            const is_status = status ? APPLICATION_MODES.ACTIVE : APPLICATION_MODES.INACTIVE;
            const commentType = this.state.commentType.label;
            // const selectedRatingType = this.state.selectedRatingType.label;
            const customerResponseStatus = this.state.isLiveFeed && this.state.isRespondable && this.state.customerResponse.label && this.state.customerResponse.label !== "" && this.state.customerResponse.label !== API_SOURCE.SELECT_COMMENT_TYPE ? this.state.customerResponse.label : "";
            let actualSource = Object.values(this.props.sourceList).filter(item => item.id === this.state.selectedApiSource.value);
            if (actualSource.length > 0) {
                actualSourceName = actualSource[0].name;
            }

            is_live_feed = isLiveFeed ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            is_public = isPublic ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            is_action_trackable = isTrackable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            is_single_review_refresh = isSingleReviewRefresh ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            is_accuracy = isAccuracy ? APPLICATION_MODES.YES : APPLICATION_MODES.NO
            if (isLiveFeed) {
                is_respondable = isRespondable ? APPLICATION_MODES.YES : APPLICATION_MODES.NO;
            } else {
                is_respondable = APPLICATION_MODES.NO;
            }

            if (google || facebook || instagram || twitter) {
                if (this.state.isValid && this.state.dateValid && this.state.validLicenseDate) {
                    selectedFormValid.push(true);
                    let status = is_status;
                    let isLiveFeed = is_live_feed;
                    let isPublic = is_public;
                    let isTrackable = is_action_trackable;
                    let isRespondable = is_respondable;
                    let valid_from = this.state.valid_from;
                    let valid_upto = this.state.valid_upto;
                    let isSingleReviewRefresh = is_single_review_refresh;
                    let isAccuracy = is_accuracy;

                    data = {
                        customer_id: this.props.customerId,
                        source_type: "apiSource",
                        source_id: this.state.selectedApiSource.value,
                        name: actualSourceName,
                        unique_name: this.state.selectedApiSource.label,
                        source_template: this.state.source_template,
                        comment_type: commentType,
                        // rating_type:selectedRatingType,
                        details: this.state.details,
                        status: status,
                        valid_from: valid_from,
                        valid_upto: valid_upto,
                        is_live_feed: isLiveFeed,
                        is_respondable: isRespondable,
                        is_public: isPublic,
                        is_action_trackable: isTrackable,
                        is_single_review_refresh: isSingleReviewRefresh,
                        response_status: customerResponseStatus,
                        is_accuracy:isAccuracy
                    };
                    if (this.state.applicationMode === "CREATE") {
                        const nanoid = customAlphabet(APPLICATION_MODES.NANO_ID_CHARACTERS, APPLICATION_MODES.NANO_ID_LENGTH);
                        let uniqueID = nanoid();
                        data._id = uniqueID,
                            data.id = uniqueID,
                            data.created_by = this.props.userName;
                        data.source_template = actualSource[0].source_template ? actualSource[0].source_template : "";
                    } else {
                        data.updated_by = this.props.userName;
                    }
                    sourceData.push(data);
                } else {
                    selectedFormValid.push(false);
                }
            }

            this.setState({sourceData});
            if (selectedFormValid.every(x => x !== true)) {
                this.setState({inValidForm: true});
            }
            if (selectedFormValid.every(x => x === true)) {

                if (facebook || instagram || google || twitter) {
                    this.setState({
                        preLoader: true,
                        formSelection: true
                    });
                    if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                        this.props.actions.createCustomerSource(sourceData, this.props.customer_id).then((response) => {
                            this.setState({preLoader: false});
                            if (response && response.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (response.data && response.data.message) {
                                    this.setAlert(response.data.message);
                                } else {
                                    this.setAlert(response.message);
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: response.data.message
                                });
                            }
                        });
                    } else {
                        this.props.actions.updateCustomerSource(sourceData, this.props.sourceData[0]._id).then((response) => {
                            this.setState({preLoader: false});
                            if (response && response.status === 200) {
                                this.setState({shouldCloseModal: true});
                                if (response.data && response.data.message) {
                                    this.setAlert(response.data.message);
                                } else {
                                    this.setAlert(response.message);
                                }
                            } else {
                                this.setState({
                                    shouldCloseModal: false,
                                    isAlertBoxOpen: true,
                                    isSuccess: false,
                                    alert_information: response.data && response.data.data.message ? response.data.data.message : response.message
                                });
                            }
                        });
                    }
                } else {
                    this.setState({formSelection: false});
                }
            }
        });
    };

    setAlert = (alertMessage) => {
        this.setState({
            isAlertBoxOpen: true,
            isSuccess: true,
            alert_information: alertMessage
        });
    };

    handleAppMode = () => {
        this.setState({applicationMode: "EDIT"});
    };

    componentDidMount() {
        this.props.actions.fetchAllSourcesByType({'type': 'apiSource'}).then(() => {
            if (this.props.sourceList) {
                this.setApiSource(this.props);
            }
        });

        this.props.actions.fetchCommentType().then(() => {
            if (this.props.commentTypes) {
                this.setCommentType(this.props);
            }
        });
       
        this.props.actions.fetchCustomerResponses().then(() => {
            if (this.props.customerResponses) {
                this.setCustomerResponse(this.props);
            }
        });

        this.props.actions.fetchCategory();
        if (this.props.sourceData) {
            this.props.sourceData.map((data) => {
                let name = `${[data.unique_name.toLowerCase()]}Value`;
                name = (name !== "facebookValue" && !name.includes("fb") && name !== "instagramValue" && name !== "google reviewsValue" &&
                    name !== "googleValue" && name !== "twitterValue") ? "twitterValue" : name;

                this.setState({
                    [name === "google reviewsValue" || name === "googleValue" ? "googleValue" : name.includes("fb") ? "fbValue" : name]: data,

                    [(data.unique_name.toLowerCase() !== "facebook" && !data.unique_name.toLowerCase().includes("fb") &&
                        data.unique_name.toLowerCase() !== "instagram" && data.unique_name.toLowerCase() !== "google reviews"
                        && data.unique_name.toLowerCase() !== "google" && data.unique_name.toLowerCase() !== "twitter") ?
                        "twitter" : (data.unique_name.toLowerCase() === "google reviews" || data.unique_name.toLowerCase() === "google" ? "google" :
                            data.unique_name.toLowerCase().includes("fb") ? "facebook" : data.unique_name.toLowerCase())]: true,

                    apiSource: data.unique_name,
                    selectedApiSource: {label: data.unique_name, value: data.source_id},
                    commentType: {label: data.comment_type, value: data.comment_type},
                    // selectedRatingType:{label: data.rating_type, value: data.rating_type},
                    customerResponse: data.response_status !== "" ? {
                        label: data.response_status,
                        value: data.response_status
                    } : ""
                });
            });

            this.setState({sourceData: this.props.sourceData});
        }

        this.setState({applicationMode: this.props.applicationMode});

        if (this.props.category) {
            this.setState({categoryList: this.props.category});
        }

        if (this.props.customerData) {
            this.setState({
                license_from: this.props.customerData.valid_from,
                license_upto: this.props.customerData.valid_upto,
            });
        }

        if (this.props.applicationMode === APPLICATION_MODES.CREATE) {
            this.setState({
                valid_from: this.props.customerData.valid_from,
                valid_upto: this.props.customerData.valid_upto,
                isPublic: true,
                isAccuracy:this.props.applicationMode === APPLICATION_MODES.CREATE ? this.props.customerData.is_accuracy === 'Yes' ? true : false : this.props.sourceData && this.props.sourceData.is_accuracy === APPLICATION_MODES.YES,
                // selectedRatingType:{label:this.props.customerData.rating_type, value:this.props.customerData.rating_type}
            });
        } else {
            if (this.props.sourceData) {
                this.props.sourceData.map((data) => {
                    this.setState({details: data.details});
                    let dateFrom = "";
                    let dateUpto = "";
                    if (data.valid_from !== undefined && data.valid_from !== "" && data.valid_upto !== undefined && data.valid_upto !== "") {
                        let arrFrom = new Date(data.valid_from).toDateString().replace('-', '').split(" ");
                        let arrUpto = new Date(data.valid_upto).toDateString().replace('-', '').split(" ");
                        dateFrom = arrFrom[2] + "-" + arrFrom[1] + "-" + arrFrom[3],
                            dateUpto = arrUpto[2] + "-" + arrUpto[1] + "-" + arrUpto[3]
                    }

                    let name = `${[data.unique_name.toLowerCase()]}Value`;
                    name = (name !== "facebookValue" && !name.includes("fb") && name !== "instagramValue" && name !== "google reviewsValue" && name !== "googleValue" && name !== "twitterValue") ? "twitterValue" : name;
                    this.setState({
                        [name === "google reviewsValue" || name === "googleValue" ? "googleValue" : name.includes("fb") ? "fbValue" : name]: data,
                        [(data.unique_name.toLowerCase() !== "facebook" && !data.unique_name.toLowerCase().includes("fb") &&
                            data.unique_name.toLowerCase() !== "instagram" && data.unique_name.toLowerCase() !== "google reviews" &&
                            data.unique_name.toLowerCase() !== "google" && data.unique_name.toLowerCase() !== "twitter") ? "twitter" : (data.unique_name.toLowerCase() === "google reviews" || data.unique_name.toLowerCase() === "google" ? "google" : data.unique_name.toLowerCase().includes("fb") ? "facebook" : data.unique_name.toLowerCase())]: true,
                        valid_from: dateFrom,
                        valid_upto: dateUpto,
                        status: trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE,
                        isLiveFeed: trimCapitalize(data.is_live_feed) === APPLICATION_MODES.YES,
                        isRespondable: trimCapitalize(data.is_respondable) === APPLICATION_MODES.YES,
                        isPublic: trimCapitalize(data.is_public) === APPLICATION_MODES.YES,
                        isTrackable: trimCapitalize(data.is_action_trackable) === APPLICATION_MODES.YES,
                        isSingleReviewRefresh: trimCapitalize(data.is_single_review_refresh) === APPLICATION_MODES.YES,
                        isAccuracy:trimCapitalize(data.is_accuracy) === APPLICATION_MODES.YES
                    });
                });
            }
        }
    }

    setApiSource = (thisProps) => {
        const apiSourceListFetched = Object.values(thisProps.sourceList);
        if (apiSourceListFetched.length > 0) {
            this.setState({apiSourceList: apiSourceListFetched});
        }
        const sourceLst = apiSourceListFetched.map((data) => {
            return {label: data.unique_name, value: data.id};
        });
        sourceLst.sort(dynamicSort("label"));
        this.setState({apiSourceList: sourceLst});
    };

    setCommentType = (thisProps) => {
        const apiCommentType = Object.entries(thisProps.commentTypes)
            .map(([key, value]) => value)
            .map(type => type.name);
        if (apiCommentType.length > 0) {
            this.setState({commentTypeList: apiCommentType});
        }

        const commentTypeLst = apiCommentType.map((data) => {
            return {label: data, value: data};
        });
        commentTypeLst.sort(dynamicSort("label"));
        this.setState({commentTypeList: commentTypeLst});
    };


    setCustomerResponse = (thisProps) => {
        if (thisProps.customerResponses && thisProps.customerResponses.length > 0) {
            const cutomerResponses = thisProps.customerResponses.map((data) => {
                return {label: data, value: data};
            });
            cutomerResponses.sort(dynamicSort("label"));
            this.setState({cutomerResponseList: cutomerResponses});
        }

    };


    componentWillReceiveProps(props) {
        if (props.sourceList) {
            this.setApiSource(props);
        }
        if (props.commentTypes) {
            this.setCommentType(props);
        }

        // if (props.sourceData.length) {
        //     let selectedRatingType = []
        //     this.state.ratingTypeListOption.map((ele)=>{
        //         if(props.applicationMode === APPLICATION_MODES.CREATE){
        //             if(props.customerData.rating_type === ele.label){
        //                 selectedRatingType = {
        //                     label: ele.label, value: ele.value
        //                 }
        //             }
        //         }else if(props.sourceData[0].rating_type === ele.label){
        //             selectedRatingType= {
        //                 label: ele.label, value: ele.value
        //             }
        //         }
        // });

            // this.setState({
            //     selectedRatingType:selectedRatingType
            // });
        //  }

        if (props.category) {
            this.setState({categoryList: props.category});
        }

        if (props.customerResponses) {
            this.setCustomerResponse(props);
        }
    }

    _handleStartDateInput = e => {
        this.ifAnyFieldChanged(true);

        let datefrom = e != null ? e.toDateString().replace('-', '').split(" ") : "";
        this.setState({
            valid_from: e != null ? datefrom[2] + "-" + datefrom[1] + "-" + datefrom[3] : "",
            min_Date: e != null ? datefrom[2] + "-" + datefrom[1] + "-" + datefrom[3] : "",
            editFlag: {
                ...this.state.editFlag,
                startDate: true
            }
        });
        if (e == null) {
            this.setState({valid_upto: ""});
        }
    };

    _handleEndDateInput = e => {
        this.ifAnyFieldChanged(true);
        let dateupto = e != null ? e.toDateString().replace('-', '').split(" ") : "";
        this.setState({
            valid_upto: e != null ? dateupto[2] + "-" + dateupto[1] + "-" + dateupto[3] : "",
            editFlag: {
                ...this.state.editFlag,
                endDate: true
            }
        });
    };

    _handleCommentTypeSelect = selectedOption => {
        this.ifAnyFieldChanged(true);
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                commentType: true
            },
            commentType: selectedOption
        });
    }

    // _handleRatingTypeSelect = selectedOption => {
    //     this.ifAnyFieldChanged(true);
    //     this.setState({
    //         editFlag: {
    //             ...this.state.editFlag,
    //             selectedRatingType: true
    //         },
    //         selectedRatingType: selectedOption
    //     });
    // }

    _handleCustomerResponseSelect = selectedOption => {
        this.ifAnyFieldChanged(true);
        this.setState({
            editFlag: {
                ...this.state.editFlag,
                customerResponse: true
            },
            customerResponse: selectedOption
        });
    }
    _handleInput = e => {
        this.ifAnyFieldChanged(true);

        if (e.target.type === "checkbox") {
            this.setState({
                [e.target.id]: e.target.checked
            });

            if (e.target.id === "isRespondable") {
                this.setState({
                    customerResponse: ""
                });
            }
        }
        this.setState({
            [e.target.name]: e.target.value,
            editFlag: {
                ...this.state.editFlag,
                [e.target.name]: true
            }
        });
    };

    handleSubmit = (data) => {
        this.ifAnyFieldChanged(true);
        this.setState({details: data.reduce((obj, item) => ({...obj, [item.rowKey]: item.rowValue}), {})})
    }

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12}
                               className={this.state.preLoader ? "valign-wrapper form-loader-overlay api-form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                {this.state.sourceData && (
                    <Fragment>
                        <Row>
                            {this.state.applicationMode === "VIEW" ? (
                                    <TatvamCol s={12}>
                                        <h4>{API_SOURCE.VIEW_TITLE_SOURCE}</h4>
                                        <div className="col s12 l12 formView p-0">
                                            <span className="label property-label col s2">Source :</span>
                                            <span className="col s10 captialize">{this.state.apiSource}</span>
                                        </div>
                                    </TatvamCol>
                                ) :
                                (
                                    <TatvamCol s={12} m={6} xl={6}>
                                        {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                                            <h4>{API_SOURCE.EDIT_SOURCE}</h4>) : ""}
                                        <label htmlFor="apiSource">{API_SOURCE.Name}</label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className="validate select-ht col s12 pr-0 "
                                            value={this.state.selectedApiSource}
                                            name="apiSource"
                                            id="apiSource"
                                            onChange={this._handleSourceSelect}
                                            options={this.state.apiSourceList}
                                            placeholder={API_SOURCE.SELECT_API_SOURCE}
                                            isDisabled={(this.state.applicationMode === "EDIT") || (this.state.applicationMode === "VIEW")}
                                        />
                                    </TatvamCol>
                                )
                            }

                            {this.state.applicationMode === "VIEW" ? (
                                <TatvamCol s={12}>
                                    <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4 pl-0"> {API_SOURCE.VIEW_START_DATE} : </span>
                                        <span className="col s8"> {this.state.valid_from}</span>
                                    </div>

                                    <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4 pl-0"> {API_SOURCE.VIEW_END_DATE} : </span>
                                        <span className="col s8"> {this.state.valid_upto}</span>
                                    </div>

                                    <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4 pl-0"> {API_SOURCE.VIEW_COMMENT_TYPE} : </span>
                                        <span className="col s8">{this.state.commentType.label}</span>
                                    </div>

                                    <div className="col s12 m6 p-0">
                                        <span className="property-label col s4"> {API_SOURCE.STATUS} : </span>
                                        <span
                                            className="col s7">{this.state.status ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}
                    </span>
                                    </div>

                                    <div className="col s12 m6 p-0">
                                        <span className="property-label col s4"> {API_SOURCE.IS_LIVE_FEED} : </span>
                                        <span
                                            className="col s8"> {this.state.isLiveFeed ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                    </div>

                                    {(this.state.isLiveFeed &&
                                        <div className="col s12 m6 p-0">
                                            <span
                                                className="property-label col s4  pl-0"> {API_SOURCE.IS_RESPONDABLE} : </span>
                                            <span
                                                className="col s7"> {this.state.isRespondable ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                        </div>
                                    )}

                                    {(this.state.isLiveFeed && this.state.isRespondable &&
                                        <div className="col s12 m6 p-0">
                                            <span
                                                className="property-label col s4 pl-0"> {API_SOURCE.RESPONSE_STATUS_LABEL} : </span>
                                            <span className="col s8">{this.state.customerResponse.label}</span>
                                        </div>
                                    )}

                                    <div className="col s12 m6 p-0">
                                        <span className="property-label col s4"> {API_SOURCE.IS_PUBLIC} : </span>
                                        <span
                                            className="col s8"> {this.state.isPublic ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                    </div>

                                    <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4"> {API_SOURCE.IS_ACTION_TRACKABLE} : </span>
                                        <span
                                            className="col s8"> {this.state.isTrackable ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                    </div>
                                    <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4"> {API_SOURCE.IS_SINGLE_REVIEW_REFRESH} : </span>
                                        <span
                                            className="col s8"> {this.state.isSingleReviewRefresh ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                    </div>
                                    <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4"> {API_SOURCE.IS_ACCURACY} : </span>
                                        <span
                                            className="col s8"> {this.state.isAccuracy ? SWITCH_TEXT.YES : SWITCH_TEXT.NO}</span>
                                    </div>
                                    {/* <div className="col s12 m6 p-0">
                                        <span
                                            className="property-label col s4 pl-0"> {API_SOURCE.RATING_TYPE} : </span>
                                        <span className="col s8">{this.state.selectedRatingType.label}</span>
                                    </div> */}
                                </TatvamCol>
                            ) : (
                                this.state.selectedApiSource && this.state.selectedApiSource.value &&
                                <TatvamCol s={12}>
                                    <TatvamCol s={12} className="formRowApi">
                                        <TatvamCol s={12} m={6} className="web">
                                            <label className="status-label">
                                                {API_SOURCE.START_DATE}<span style={{color: 'red'}}> *</span>
                                            </label>
                                            <TatvamDatePicker
                                                selected={this.state.valid_from && new Date(this.state.valid_from)}
                                                onChange={this._handleStartDateInput}
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText={API_SOURCE.DATE_FORMAT}
                                                className={(this.state.inValidForm && !this.state.valid_from) ? "date-input invalid" : "date-input"}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </TatvamCol>

                                        <TatvamCol s={12} m={6} className="web">
                                            <label className="status-label">
                                                {API_SOURCE.END_DATE}<span style={{color: 'red'}}> *</span>
                                            </label>
                                            <TatvamDatePicker
                                                selected={this.state.valid_upto && new Date(this.state.valid_upto)}
                                                onChange={this._handleEndDateInput}
                                                minDate={this.state.min_Date && new Date(this.state.min_Date)}
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText={API_SOURCE.DATE_FORMAT}
                                                className={(this.state.inValidForm && !this.state.valid_upto) ? "date-input invalid" : "date-input"}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </TatvamCol>
                                    </TatvamCol>

                                    <TatvamCol s={12} m={6} xl={6}>
                                        <label htmlFor="apiSource">{API_SOURCE.COMMENT_TYPE_LABEL}<span
                                            style={{color: 'red'}}> *</span></label>
                                        <TatvamMultiSelect
                                            required
                                            m={6}
                                            classNamePrefix="react-select"
                                            className={(this.state.inValidForm && (this.state.commentType === "" || this.state.commentType.label === "")) ? "validate select-ht invalid-field" : "validate select-ht"}
                                            value={this.state.commentType}
                                            name="commentType"
                                            id="commentType"
                                            onChange={this._handleCommentTypeSelect}
                                            options={this.state.commentTypeList}
                                            placeholder={API_SOURCE.SELECT_COMMENT_TYPE}
                                        />
                                    </TatvamCol>

                                    <TatvamCol s={12} m={6} className="switch-wrapper">
                                        <Switch
                                            id="status"
                                            checked={this.state.status}
                                            onClick={this._handleInput}
                                            offLabel={API_SOURCE.STATUS}
                                            m={6}
                                        />
                                    </TatvamCol>

                                    <TatvamCol m={12} className="mb-0 p-0 pt-1">
                                        <TatvamCol s={6} className="switch-wrapper">
                                            <Switch
                                                id={"isLiveFeed"}
                                                offLabel={API_SOURCE.IS_LIVE_FEED}
                                                checked={this.state.isLiveFeed}
                                                onClick={this._handleInput}
                                                m={6}
                                            />
                                        </TatvamCol>

                                        {(this.state.isLiveFeed &&
                                            <TatvamCol s={6} m={3} className="switch-wrapper">
                                                <Switch
                                                    id={"isRespondable"}
                                                    checked={this.state.isRespondable}
                                                    onClick={this._handleInput}
                                                    offLabel={API_SOURCE.IS_RESPONDABLE}
                                                    m={6}
                                                />
                                            </TatvamCol>
                                        )}

                                        {(this.state.isLiveFeed && this.state.isRespondable &&
                                            <TatvamCol s={12} m={6} xl={3} className={"ht-55"}>
                                                <label htmlFor="apiSource">{API_SOURCE.RESPONSE_STATUS_LABEL}</label>
                                                <TatvamMultiSelect
                                                    required
                                                    m={6}
                                                    classNamePrefix="react-select"
                                                    className="validate select-ht"
                                                    value={this.state.customerResponse}
                                                    name="response_status"
                                                    id="response_status"
                                                    onChange={this._handleCustomerResponseSelect}
                                                    options={this.state.cutomerResponseList}
                                                    placeholder={API_SOURCE.SELECT_COMMENT_TYPE}
                                                />
                                            </TatvamCol>
                                        )}
                                    </TatvamCol>

                                    <TatvamCol s={12} m={6} className="switch-wrapper pt-2">
                                        <Switch
                                            id={"isPublic"}
                                            checked={this.state.isPublic}
                                            onClick={this._handleInput}
                                            offLabel={API_SOURCE.IS_PUBLIC}
                                            m={6}
                                        />
                                        <Switch
                                            id={"isAccuracy"}
                                            checked={this.state.isAccuracy}
                                            onClick={this._handleInput}
                                            offLabel={API_SOURCE.IS_ACCURACY}
                                            m={6}
                                        />
                                    </TatvamCol>

                                    <TatvamCol m={6} className="switch-wrapper pt-2">
                                        <Switch
                                            id={"isTrackable"}
                                            checked={this.state.isTrackable}
                                            onClick={this._handleInput}
                                            offLabel={API_SOURCE.IS_ACTION_TRACKABLE}
                                            m={6}
                                        />

                                        <Switch
                                            id={"isSingleReviewRefresh"}
                                            checked={this.state.isSingleReviewRefresh}
                                            onClick={this._handleInput}
                                            offLabel={API_SOURCE.IS_SINGLE_REVIEW_REFRESH}
                                            m={6}
                                        />
                                    </TatvamCol>

                                    {/* <TatvamCol s={12} m={6} xl={6}>
                                        <label htmlFor="apiSource">{API_SOURCE.RATING_TYPE}<span
                                            style={{color: 'red'}}> *</span></label>
                                        <TatvamMultiSelect
                                            required
                                            m={6}
                                            classNamePrefix="react-select"
                                            className={(this.state.inValidForm && (this.state.selectedRatingType === "" || this.state.selectedRatingType.label === "")) ? "validate select-ht invalid-field" : "validate select-ht"}
                                            value={this.state.selectedRatingType}
                                            name="ratingType"
                                            id="ratingType"
                                            onChange={this._handleRatingTypeSelect}
                                            options={this.state.ratingTypeListOption}
                                            placeholder={API_SOURCE.SELECT_COMMENT_TYPE}
                                        />
                                    </TatvamCol> */}
                                </TatvamCol>
                            )
                            }

                            {this.state.selectedApiSource.label &&
                                <TatvamCol m={12} className="p-0">
                                    <ApiDetails
                                        apiDetails={this.state.details}
                                        onSubmit={this.handleSubmit}
                                        disabled={this.state.applicationMode === "VIEW"}
                                        inValidForm={this.state.inValidForm}
                                    />
                                </TatvamCol>
                            }
                            <p className="errorText col s12">  {!this.state.formSelection && "Select a source"}</p>
                            {!this.state.isValid && <p className="errorText col s12"></p>}
                            {!this.state.validLicenseDate &&
                                <p className="errorText col s12">{VALIDATION_MESSAGES.EXCEED_LICENSE_DATE}</p>}
                            {!this.state.dateValid &&
                                <p className="errorText col s12">{VALIDATION_MESSAGES.START_DATE_SHOULD_BE_LESS_THAN_END_DATE}</p>}
                        </Row>
                        <Row className="modalButtons">
                            {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                    <TatvamButton
                                        waves="light"
                                        className="mt-1  CreateProjectPublish btn_primary "
                                        onClick={this._handleSave}
                                        disabled={!this.state.isAnyFormChanged}
                                    >
                                        {FORM_OPERATIONS.SAVE}
                                    </TatvamButton>
                                ) :
                                this.props.sourcePermission === APP_BOOLEANS.TRUE && (
                                    <TatvamButton
                                        waves="light"
                                        className="mt-1 CreateProjectPublish btn_primary "
                                        name="EDIT"
                                        onClick={this.handleAppMode}
                                    >
                                        {FORM_OPERATIONS.EDIT}
                                    </TatvamButton>
                                )}
                            <TatvamButton
                                waves="light"
                                className="mt-1 mr-1 CreateProjectPublish btn_primary"
                                onClick={this._triggerDiscard}
                            >
                                {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                                !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                    FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                            </TatvamButton>
                        </Row>
                    </Fragment>
                )}
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default ApiSourceForm;

